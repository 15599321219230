// import firebase from "firebase/app";
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyARsC0XGtxy6P3tCgy8AuB_x___q27URKs",
    authDomain: "my-website-dd472.firebaseapp.com",
    databaseURL: "https://my-website-dd472.firebaseio.com",
    projectId: "my-website-dd472",
    storageBucket: "my-website-dd472.appspot.com",
    messagingSenderId: "1059622105538",
    appId: "1:1059622105538:web:e2b212e512fd99b498265b"
};

//init firebase
const app = initializeApp(firebaseConfig)

//init services
const projectFirestoreDb = getFirestore(app);
const projectFirebaseStorage = getStorage(app)
const projectFirebaseAuth = getAuth(app)

export { projectFirestoreDb, projectFirebaseStorage, projectFirebaseAuth }