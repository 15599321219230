
import {defineComponent, onMounted, ref} from "vue";
import vueRecaptcha from "vue3-recaptcha2"
import {Timestamp} from "firebase/firestore";
import ModalA from "@/components/ModalA.vue";
import * as emailjs from 'emailjs-com';
import { Resend } from 'resend';



export default defineComponent({
  name: "ContactForm",
  components:{ModalA, vueRecaptcha },
  setup() {
    let vr: any
    const vueRecaptcha = ref(null)
    const modalActive = ref(false)
    const fullName = ref('')
    const email = ref('')
    const subject = ref('')
    const message = ref('')
    const submitDisabled = ref(true)

    onMounted(()=> {
      vr = vueRecaptcha.value
    })

    const successMessage = ref<boolean | string>(false)

    const validateEmail = () => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
        return true
      } else {
      alert(`${email.value} is NOT a valid email address`)
      return false
      }
    }
    const validateMessage = ()=> {
      if(message.value.length < 10) {
        alert(`"${message.value}" is not a valid message!`)
        return false
      } else {
        return true
      }
    }

    const recaptchaVerified = (response: any)=> {
      if(response) submitDisabled.value = false
    }
    const recaptchaExpired = (response: any) => {
      if(!response) submitDisabled.value = true
    }


    const sendMail = async () => {
      const resend = new Resend(process.env.VUE_APP_RESEND_API_KEY);

      let messageData = {
        name: fullName.value,
        email: email.value,
        subject: subject.value,
        message: message.value,
        timestamp: Timestamp.now()
      }
      //Validate
      if(validateEmail() && validateMessage()) {
        console.log("Email Validated")
        console.log("Message Validated")
      }

      const { data, error } = await resend.emails.send({
        from: 'SITE ROBOT <messages@joshuamanlunas.com>',
        to: ['joshuamanlunas@icloud.com'],
        subject: 'New Site Enquiry',
        html: `<div>
                <p><strong>Name:</strong> ${messageData.name}</p>
                <p><strong>Email:</strong> ${messageData.email}</p>
                <p><strong>Subject:</strong> ${messageData.subject}</p>
                <p><strong>Message:</strong> ${messageData.message}</p>
                <p><strong>Timestamp:</strong> ${messageData.timestamp.toDate().toString()}</p>
              </div>` ,
      });

      if (error) {
        return console.error({ error });
      }

      console.log({ data });

      successMessage.value = "Message sent!"
      toggleModal()
      //clear fields
      fullName.value = '';
      email.value = '';
      subject.value = '';
      message.value = ''

      //reset vue-recaptcha
      vr.reset()
    }

      // await emailjs.send('service_rqe65l4', 'template_f0yn33u',  {
      //   to_name: 'Site Admin Joshua',
      //   from_name: messageData.name,
      //   reply_to: messageData.email,
      //   message: messageData.message,
      // }, 'x82Ax95igD2Smvs-8')

      // successMessage.value = "Message sent!"
      // toggleModal()
      // //clear fields
      // fullName.value = '';
      // email.value = '';
      // subject.value = '';
      // message.value = ''
      //
      // //reset vue-recaptcha
      // vr.reset()

    // }

    const toggleModal = ()=> {
      modalActive.value = !modalActive.value
    }

    return {
      vueRecaptcha,
      fullName,
      email,
      subject,
      message,
      successMessage,
      modalActive,
      submitDisabled,
      recaptchaExpired,
      recaptchaVerified,
      toggleModal,
      sendMail
    }
  }
})
