import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "content-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MobileNav = _resolveComponent("MobileNav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      firstName: _ctx.firstName,
      lastName: _ctx.lastName,
      title: _ctx.title,
      socialMediaLinks: _ctx.socialMediaLinks
    }, null, 8, ["firstName", "lastName", "title", "socialMediaLinks"]),
    _createVNode(_component_MobileNav),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            mode: "out-in",
            onBeforeEnter: _ctx.routeBeforeEnter,
            onEnter: _ctx.routeEnter,
            onLeave: _ctx.routeLeave
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1032, ["onBeforeEnter", "onEnter", "onLeave"])
        ]),
        _: 1
      })
    ])
  ]))
}