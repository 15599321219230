
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import getAboutMeData from "@/composables/getAboutMeData";

export default defineComponent({
  name: "AboutMeView",
  setup() {
    const { aboutMe, address, age, email, phone, residence, whatIdo, getData } = getAboutMeData()
    getData()
    return { aboutMe, address, age, email, phone, residence, whatIdo }
  }
})
