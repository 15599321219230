
import {defineComponent} from "vue";
import ContactForm from "@/components/ContactForm.vue";

export default defineComponent({
  name: "ContactView",
  components: {ContactForm},
  data() {
    return {
      contactInfo: [
        {info: 'Adelaide SA, Australia', icon: 'lnr lnr-map-marker', text: 'location', id: 1},
        {info: '+61 (0) 401 861 288', icon: 'lnr lnr-phone-handset', text: 'mobile', id: 2},
        {info: 'joshua@joshuamanlunas.com', icon: 'lnr lnr-map-marker', text: 'email', id: 3},
        {info: 'Freelance Available', icon: 'lnr lnr-checkmark-circle', text: 'optional service', id: 4},
      ],
    }
  }

})
