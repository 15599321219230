
import {defineComponent, ref} from "vue";
import Nav from "@/components/Nav.vue";
import getResume from "@/composables/getResume";

export default defineComponent({
  name: "Header",
  setup() {
    const { downloadUrl, download } = getResume()
    download()

    return { downloadUrl }
  },
  components: {Nav},
  props: ['firstName', 'lastName', 'title', 'socialMediaLinks'],
  data() {
    return {}
  }
})
