import {computed, reactive, ref} from 'vue';
import {useAboutMeStore} from "@/store/aboutMeStore";

const getAboutMeData = () => {
    const aboutMeStore = useAboutMeStore()
    const aboutMe = ref<string>()
    const address = ref<string>()
    const age = ref<string>()
    const email = ref<string>()
    const phone = ref<string>()
    const residence = ref<string>()
    let whatIdo = reactive({
        service1: '',
        service2: '',
        service3: '',
        service4: '',
    })

    const getData = async () => {
        try {
                let initData = aboutMeStore.getAboutMeData
                aboutMe.value = initData?.aboutMe
                address.value = initData?.address
                age.value = initData?.age
                email.value = initData?.email
                phone.value = initData?.phone
                residence.value = initData?.residence

                let {service1, service2, service3, service4} = initData?.whatIDo

                // @ts-ignore
            whatIdo["service1"] = service1
                // @ts-ignore
            whatIdo.service2 = service2
                // @ts-ignore
            whatIdo.service3 = service3
                // @ts-ignore
            whatIdo.service4 = service4

        } catch (e: any) {
            console.log(e.value)
        }
    }

    return {
        aboutMe,
        address,
        age, email, phone, residence,
        whatIdo,
        getData
    }
}

export default getAboutMeData