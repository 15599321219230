
import {defineComponent, PropType} from "vue";
import experience from "@/composables/dataTypes/experience";

export default defineComponent({
  name: "Experience",
  props: {
    experience: { required: true, type: Array as unknown as PropType<experience>}
  }
})
