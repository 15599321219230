
import {defineComponent} from "vue";
import gsap from "gsap";

export default defineComponent({
  name: "ModalA",
  props: ['modalActive'],
  setup() {
    const modalBeforeEnter = (el: any)=> {
      gsap.set(el, {opacity: 0})
    }
    const modalEnter = (el: any)=> {
      gsap.to(el, {
        duration: 1,
        opacity: 1,
        ease: 'power4'
      })
    }
    const modalLeave = (el: any)=> {
      gsap.to(el, {
        duration: 0.5,
        opacity: 0,
        ease: 'power1'
      })
    }

    return {
      modalBeforeEnter,
      modalEnter,
      modalLeave
    }
  }
})
