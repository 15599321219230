
import Header from "@/components/Header.vue";
import {defineComponent, ref} from "vue";
import ArrowNav from "@/components/ArrowNav.vue";
import MobileNav from "@/components/MobileNav.vue";
import gsap from "gsap";
import getLandingData from "@/composables/getLandingData";

export default defineComponent({
  components: {MobileNav, ArrowNav, Header,},

  setup() {
    const routeBeforeEnter = (el: any) => {
      gsap.set(el, {opacity: 0})
    }

    const routeEnter = (el: any, done: any) => {
      gsap.to(el, {
        duration: 2, opacity: 1, ease: 'power3', onComplete: done
      })
    }

    const routeLeave = (el: any, done: any) => {
      gsap.to(el, {
        duration: 0.5, x: 10, opacity: 0, ease: 'power4', onComplete: () => {
          done()
          el.style.hidden = true
        }
      })
    }
    const {firstName, lastName, title, socialMediaLinks, load} = getLandingData()
    load()

    return {firstName, lastName, title, socialMediaLinks, routeBeforeEnter, routeEnter, routeLeave}
  }
})
