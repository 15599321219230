import { projectFirebaseStorage } from "@/firebase/config";
import { ref, getDownloadURL } from "firebase/storage"
import { ref as vueRef } from "vue"

const getResume = ()=> {
    const fileRef = ref(projectFirebaseStorage, 'jmanlunas-cv-6.9.24.pdf')
    const downloadUrl = vueRef<any>()

    const download = async()=> {
         await getDownloadURL(fileRef).then(url=> {
             downloadUrl.value = url
        }).catch( error => {
            console.log(error)
        })
    }
    return { downloadUrl , download }
}

export default getResume