import { ref } from 'vue';
import {useLandingPageStore} from "@/store/landingPageStore";

const getHomeData = ()=> {
    const firstName = ref<string | any>()
    const lastName = ref<string>()
    const secondaryTitles = ref<any>([])
    const landingPage  = useLandingPageStore()

    const load = async ()=> {
        try {
            let initData = landingPage.getLandingPageData

            firstName.value = initData?.name.firstName
            lastName.value = initData?.name.lastName
            secondaryTitles.value = initData?.titlesSecondary
        }catch (e: any) {
            console.log(e.value)
        }
    }
    return {
        firstName,
        lastName,
        secondaryTitles,
        load
    }
}

export default getHomeData


