import {computed, ref} from 'vue';
import {projectFirestoreDb} from '@/firebase/config'
import {collection, getDocs, doc, getDoc} from "firebase/firestore"
import experience from "@/composables/dataTypes/experience";
import Education from "@/composables/dataTypes/education";

const getResumeData = () => {
    const knowledges = ref<string[]>([])
    const experience = ref<experience[]>([])
    const education = ref<Education[]>([])


    const getData = async () => {
        try {
            const resumePageData = await doc(projectFirestoreDb, 'resumeData', '8drWjicclQku9SC7GLIb')
            await getDoc(resumePageData).then( doc => {

                let initData = doc.data()
                knowledges.value = initData?.knowledges
                experience.value = initData?.experience
                education.value = initData?.education
            })
        } catch (e: any) {
            console.log(e.value)
        }
    }

    return {
        knowledges,
        experience,
        education,
        getData
    }
}

export default getResumeData