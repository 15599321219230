import {defineStore} from "pinia";

export const useAboutMeStore = defineStore({
    id: "aboutMe",
    state: () => ({
        aboutMe: `A details and results oriented professional and a highly adaptable Agile Scrum practitioner, 
            with extensive experience in developing, testing and deploying of web based and software applications 
            including APIs. Comprehensive experience in development, test automation for databases, API/web applications 
            and software applications, including unit, functional, non-functional, acceptance, integration, regression, 
            end-to-end, business-verification, operational-acceptance, exploratory and API testing.`,
        address: "Kurralta Park",
        age: "44",
        email: "joshua@joshuamanlunas.com",
        phone: "+61 (0) 401 861 288",
        residence: "Adelaide, South Australia",
        whatIDo: {
            service1: {
                description: `I help and guide clients to get to a well-designed solution that is fit for 
                purpose and is also progressive enough to be future proof. This can be achieved by analysing their 
                current system and looking at data regarding performance, bottlenecks and areas of improvement. 
                Inputs from key stakeholders and end-users are highly considered when creating the final system 
                design. Final design and implementation plans are produced including high-level testing plans where 
                it’s applicable.`,
                title: "Analysis and Design"
            },
            service2: {
                description: `I build software solutions to help solve challenges unique to my clients. 
                I use industry coding standards and best practices with proven design patterns that fit the needs 
                for the project. I advocate for clean coding and high code reuse and modularisation in the codebase 
                that I create. I am comfortable programming in an Object-Oriented model and also in a Functional 
                model depending on the client’s project’s needs. I support the open-source community by following 
                the mantra of “open-source first” when building software solutions.`,
                title: "Development and Implementation"
            },
            service3: {
                description: `I build software solutions with testability in mind. There’s nothing worse than an 
                un-testable software application as it puts the quality of the customer experience in jeopardy and 
                sometimes even their security. For me, testing is such an integral part of building a software 
                solution that it should also be a driving force in how the software should be developed. Also being 
                efficient is the key to software development (and testing), to this end, I have built numerous bespoke 
                test automation frameworks and pipelines for past and present clients. Test automation(and automation 
                in general) is such a big part of the modern Software Development Lifecycle as it enables efficiency 
                in software development and the client’s ability to deliver high-quality customer value fast.`,
                title: "Verification and Testing"
            },
            service4: {
                description: `Fast feedback, Automation and fast (de-risked) continuous deployments are the backbones 
                of the modern IT landscape. Everything that can be automated should be automated. Not doing so will 
                eventually cause the client to be left behind with the competition. Especially since the modern 
                customer demands new features at an unprecedented rate, and they also hate downtimes. For this reason, 
                I have built continuous integration and continuous deployment pipelines for past and present clients 
                using different container platforms for different applications, like microservices, single-page 
                applications and databases including a data warehousing solution.`,
                title: "Continuous Integration and Deployment"
            },
        }
    }),
    getters: {
        getAboutMeData(state) {
            return state
        }
    },
    actions: {},
})