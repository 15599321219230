
import {defineComponent, ref} from "vue";
import {doc, updateDoc, arrayUnion} from "firebase/firestore";
import {projectFirestoreDb} from "@/firebase/config";

export default defineComponent({
  name: "DataAdder",
  setup() {
    const company = ref('')
    const description = ref('')
    const period = ref('')
    const position = ref('')
    const data = {
      company: company.value,
      description: description.value,
      period: period.value,
      position: position.value
    }

    const addData = async ()=> {
      const dbreference = doc(projectFirestoreDb, 'resumeData', '8drWjicclQku9SC7GLIb')
      // const res = await updateDoc(dbreference, data)

      // Atomically add a new region to the "regions" array field.
      let res = await updateDoc(dbreference, {
        experience: arrayUnion({
          company: company.value,
          description: description.value,
          period: period.value,
          position: position.value
        })
      }).catch(err => console.log(err));

      console.log(res)
    }


    return { company, description, period, position, addData }
  }
})
