import { ref } from "vue"
import gsap from "gsap";

const homepageAnimation = ()=> {
    const t_zero = ref<boolean>(true)
    const t_one = ref<boolean>(false)

    const beforeEnter = (el: any, done: any) => {
        el.style.opacity = 0
    }

    const enter = (el: any, done: any) => {
        gsap.to(el, {
            duration: 5.5,
            opacity: 1, ease: 'power4',
            onComplete: done
        })
    }

    const afterEnter = (el: any, done: any) => {
        gsap.to(el, {
            duration: 4, opacity: 0, ease: 'power4',
            onComplete: done
        }).then(() => {
            t_zero.value = !t_zero.value
            t_one.value = !t_one.value
        })
    }

    return { beforeEnter, enter, afterEnter, t_zero, t_one }
}

export default homepageAnimation