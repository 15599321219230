import { ref } from 'vue';
import { projectFirestoreDb } from '@/firebase/config'
import { collection, getDocs, doc, getDoc } from "firebase/firestore"
import {useLandingPageStore} from "@/store/landingPageStore";

const getLandingData = ()=> {
    const firstName = ref<string>()
    const lastName = ref<string>()
    const title = ref<string>()
    const socialMediaLinks = ref<any>([])
    const landingPage  = useLandingPageStore()

    const load = async ()=> {
        try {
            let initData = landingPage.getLandingPageData

            firstName.value = initData?.name.firstName
            lastName.value = initData?.name.lastName
            title.value = initData?.title
            socialMediaLinks.value = initData?.socialMedia
        }catch (e: any) {
            console.log(e.value)
        }
    }
    return {
        firstName,
        lastName,
        title,
        socialMediaLinks,
        load }

}

export default getLandingData