
import {defineComponent, ref} from 'vue';
import homepageAnimation from "@/composables/homepageAnimation";
import getHomeData from "@/composables/getHomeData";

export default defineComponent({
  name: 'HomeView',
  components: {},
  props: ['firstName', 'lastName', 'secondaryTitles'],
  setup() {
    const {
      firstName,
      lastName,
      secondaryTitles,
      load
    } = getHomeData()
    load()
    const { beforeEnter, enter, afterEnter, t_zero, t_one } = homepageAnimation()
    return {
      beforeEnter, enter, afterEnter, t_zero, t_one,
      firstName,
      lastName,
      secondaryTitles
    }
  }
});
