import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ceb6784"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-id": "home",
  class: "start-page"
}
const _hoisted_2 = { class: "section-content vcentered" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 col-md-12 col-lg-12" }
const _hoisted_5 = { class: "title-block" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  class: "item",
  style: {"position":"absolute","left":"50%"}
}
const _hoisted_8 = {
  key: 0,
  class: "sp-subtitle"
}
const _hoisted_9 = {
  key: 1,
  class: "sp-subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.firstName) + " " + _toDisplayString(_ctx.lastName), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_TransitionGroup, {
                  appear: "",
                  onBeforeEnter: _ctx.beforeEnter,
                  onEnter: _ctx.enter,
                  onAfterEnter: _ctx.afterEnter
                }, {
                  default: _withCtx(() => [
                    (_ctx.t_zero)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.secondaryTitles[0]), 1))
                      : _createCommentVNode("", true),
                    (_ctx.t_one)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.secondaryTitles[1]), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["onBeforeEnter", "onEnter", "onAfterEnter"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}