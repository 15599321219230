import {defineStore} from "pinia";

export const useLandingPageStore = defineStore({
    id: "landingPage",
    state: () => ({
        name: { firstName: "Joshua", lastName: "Manlunas" },
        socialMedia: [
            { name: "instagram", link: "https://www.instagram.com/joshuamanlunas" },
            { name: "twitter", link: "https://twitter.com/JoshuaManlunas" },
            { name: "linkedin", link: "https://www.linkedin.com/in/joshuamanlunas" },
            { name: "github", link: "https://github.com/joshuaManlunas" },

        ],
        title: "Analyst Programmer | Automation Engineer",
        titlesSecondary: ['Analyst Programmer', 'Automation Engineer'],
    }),
    getters: {
        getLandingPageData(state) {
            return state
        }
    },
    actions: {},
})