
import {defineComponent, PropType} from "vue";
import Education from "@/composables/dataTypes/education";

export default defineComponent({
  name: "Education",
  props: {
    education: { required: true, type: Array as unknown as PropType<Education>}
  }
})
