
import {defineComponent, ref} from "vue";
import Knowledges from "@/components/Knowledges.vue";
import getResumeData from "@/composables/getResumeData";
import Experience from "@/components/Experience.vue";
import Education from "@/components/Education.vue";

export default defineComponent({
  name: "ResumeView",
  components: {Education, Experience, Knowledges },
  setup() {
    const designSkills = ref([
      { skill: 'Framework Design', level: '95%'},
      { skill: 'Application Solution Design', level: '90%'},
      { skill: 'API Design', level: '85%'},
    ])

    const codingSkills = ref([
      { skill: 'Kotlin', level: '90%'},
      { skill: 'Golang', level: '95%'},
      { skill: 'Java', level: '90%'},
      { skill: 'Typescript', level: '89%'},
      { skill: 'Javascript', level: '89%'},
      { skill: 'Groovy', level: '95%'},
      { skill: 'HTML/CSS', level: '80%'},
      { skill: 'Bash', level: '80%'},
      { skill: 'C#', level: '80%'},
    ])
    const { knowledges, experience, education, getData } = getResumeData()
    getData()

    return { knowledges, experience, education, designSkills, codingSkills }
  }
})
